import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject, Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { AccountService } from '../../common/services/account.service';
import { Account } from '../../common/interfaces/account';
import { Data } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {FormShareteamService} from '../../common/services/form-shareteam.service';
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../common/toast";
import {environment} from "../../../environments/environment";
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {DashboardTeamLeaguemodalComponent} from "../dashboard-teamleaguemodal/dashboard-teamleaguemodal.component";
import {SessionStorageService} from "ngx-webstorage";

@Component({
  selector: 'app-teamshare',
  templateUrl: './dashboard-team-share.component.html',
  styleUrls: ['./dashboard-team-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})



export class DashboardTeamShareComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() noheader: boolean;
  @Input() team_id: string;
  @Input() repeat_id: string;
  @Input() team_name: string;

  account: Account;
  s1 = null;
  isDisabled = true;

  teamname = '';
  name = '';
  teammate = '';
  sms = '';
  link = '';
  email = '';
  oteamname = '';

  constructor(
    private sessionSt: SessionStorageService, private bottomSheetRef: MatBottomSheetRef, public formService: FormShareteamService, private accountService: AccountService, @Inject(MAT_BOTTOM_SHEET_DATA) public data: Data,  public dialog: MatDialog, private http: HttpClient, private toastService: ToastService) {
  }

  ngAfterViewInit() {

  }

  ngOnInit() {


    if(this.sessionSt.retrieve( 'team_skip') == true){
      this.close();
    }

    this.formService.initializeFormGroup();

    this.formService.form.controls.teamname.disable();
    this.formService.form.controls.link.disable();
    this.formService.form.controls.name.disable();
    this.oteamname = this.data.id.team_name;

    this.formService.form.valueChanges.subscribe(data => {

      this.teamname = data.teamname;
      this.name = data.name;
      this.teammate = data.teammate;
      this.sms = data.sms;
      //this.link = data.link;
      this.email = data.email;

    });

    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;

      if(this.team_id !== undefined){
        this.formService.form.controls.teamname.setValue(this.team_name);
        this.formService.form.controls.name.setValue(this.account.current_profile.name);
        this.formService.form.controls.link.setValue('https://trackithub.com/shareteam/' + this.team_id + '/' + this.repeat_id);
        this.link = 'https://trackithub.com/shareteam/' + this.team_id + '/' + this.repeat_id;
      } else {
        this.formService.form.controls.teamname.setValue(this.data.id.team_name);
        this.formService.form.controls.name.setValue(this.account.current_profile.name);
        this.formService.form.controls.link.setValue('https://trackithub.com/shareteam/' + this.data.id.team_id + '/' + this.data.id.rp_id);
        this.link = 'https://trackithub.com/shareteam/' + this.data.id.team_id + '/' + this.data.id.rp_id;
      }


    });

  }


  sendinvite(){


    console.log(this.formService.form.controls.teammate.value);

    if(this.formService.form.controls.teammate.value === ''){
      this.toastService.show({
        text: `You must enter your teammate's name.`,
        type: 'warn',
      });
    } else {
      if(this.formService.form.controls.sms.value === '' && this.formService.form.controls.email.value === ''){
        this.toastService.show({
          text: `You need to enter an email or phone number.`,
          type: 'warn',
        });
      } else {
        if(this.formService.form.controls.sms.value !== '') {
          this.http.post<any>(environment.service.trackithubapi + '/api/inside/sendsms', {
            'phone': this.formService.form.controls.sms.value,
            'teammate_name': this.formService.form.controls.teammate.value,
            'team_name': this.formService.form.controls.teamname.value,
            'link': this.link,
            'function': 'registerteam'
          }).subscribe((data: any) => {
            this.oteamname = this.formService.form.controls.teamname.value;
            this.toastService.show({
              text: `You have successfully sent a sms invite.`,
              type: 'success',
            });
          });
        }

        if(this.formService.form.controls.email.value !== '') {
          this.http.post<any>(environment.service.trackithubapi + '/api/inside/sendteamemail', {
            'email': this.formService.form.controls.email.value,
            'teammate_name': this.formService.form.controls.teammate.value,
            'team_name': this.formService.form.controls.teamname.value,
            'link': this.link
          }).subscribe((data: any) => {
            this.oteamname = this.formService.form.controls.teamname.value;
            this.toastService.show({
              text: `You have successfully sent a email invite.`,
              type: 'success',
            });
          });
        }


      }
    }
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

  toggleDisable() {
    this.isDisabled = !this.isDisabled;
    if(this.isDisabled === true){
      if(this.formService.form.controls.teamname.value !== this.oteamname) {
        this.http.post<any>(environment.service.trackithubapi + '/api/inside/changeteamname', {
          'team': this.data.id.team_id,
          'team_name': this.formService.form.controls.teamname.value}).subscribe((data: any) => {
          this.oteamname = this.formService.form.controls.teamname.value;
          this.toastService.show({
            text: `New teamname has been saved.`,
            type: 'success',
          });
        });
      }
      this.formService.form.controls.teamname.disable();
    } else {
      this.formService.form.controls.teamname.enable();
    }
  }

  copyInputMessage(inputElement) {
    this.formService.form.controls.link.enable();
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.formService.form.controls.link.disable();
    this.toastService.show({
      text: `Link Copied to Clipboard.`,
      type: 'success',
    });
  }

  close(){

    const dialogRef = this.dialog.open(DashboardTeamLeaguemodalComponent, {
      maxWidth: 500,
      minWidth: 370,
      maxHeight: '90vh', // Adjust this based on your requirements
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.sessionSt.clear('teamshare');
      this.sessionSt.clear('team_name');
      this.sessionSt.clear('joinoutside');
      this.sessionSt.clear('team_id');
      this.sessionSt.clear( 'team_skip');
      window.location.replace("/dashboard/overview");
    });

    this.bottomSheetRef.dismiss();
  }

}
