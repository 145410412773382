import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {EventsCalendarFullComponent} from './events';
import {PricingComponent} from './common/frontend-components/pricing/pricing.component';
import {LoginComponent} from './common/frontend-components/login/login.component';
import {HomeComponent} from './frontend';
import {PrivacyPolicyComponent} from './terms/privacy-policy/privacy-policy.component';
import {RegisterComponent} from './register/register.component';
import {
  AmbassadorTableComponent,
  DashboardCreateGroupComponent,
  DashboardOverviewComponent,
  DashboardProfileComponent,
  DashboardStandingsComponent, DashboardVieweventComponent, DashboardViewrepeateventComponent
} from './dashboard';
import {AccountSettingsComponent} from './settings/account-settings';
import {PaymentSettingsComponent} from './settings/account-settings';
import {DashboardFrameComponent} from './dashboard/dashboard-frame/dashboard-frame.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {MapResultsComponent} from './frontend/map-results/map-results.component';
import {ErrorPageComponent} from './error-page/error-page.component';
import {StandingsResultsComponent} from './standings/standings-results/standings-results.component';
import {RosterTableComponent} from './dashboard/dashboard-roster/roster-table.component';
import {StandingsTableComponent} from './standings/standings-table/standings-table.component';
import {StandingsScorecardComponent} from './standings/standings-scorecard/standings-scorecard.component';
import {DashboardEditeventComponent} from './dashboard/dashboard-editevent/dashboard-editevent.component';
import {DashboardCopyeventComponent} from './dashboard/dashboard-copyevent/dashboard-copyevent.component';
import {DashboardEditgroupComponent} from './dashboard/dashboard-editgroup/dashboard-editgroup.component';
import {DashboardMembershipadminComponent} from './dashboard/dashboard-membershipadmin/dashboard-membershipadmin.component';
import {DashboardImportComponent} from './dashboard/dashboard-import/dashboard-import.component';
import {DashboardGroupsComponent} from './dashboard/dashboard-groups/dashboard-groups.component';
import {DashboardMapComponent} from './dashboard/dashboard-map/dashboard-map.component';
import {DashboardPhotosComponent} from './dashboard/dashboard-photos/dashboard-photos.component';
import {DashboardAnnouncementsComponent} from './dashboard/dashboard-announcements/dashboard-announcements.component';
import {DashboardEditrepeatComponent} from './dashboard/dashboard-editrepeat/dashboard-editrepeat.component';
import {DashboardTrialAdminComponent} from './dashboard/dashboard-trialadmin/trialadmin.component';
import {DashboardSubAdminComponent} from './dashboard/dashboard-subadmin/subadmin.component';
import { DashboardSettingsComponent } from './dashboard/dashboard-settings/dashboard-settings.component';
import {LeaguesComponent} from './frontend/leagues/leagues.component';

export const routes: Routes = [
  {
    path: 'share/:id',
    component: HomeComponent,
    data: {
      auth: false,
      title: 'About',
      description: 'TrackitHub specializes in the management and organization of Premier Pickleball Leagues and Pickleball Clubs around the world.'
    }
  }, {
    path: 'league/:league',
    component: HomeComponent,
    data: {
      auth: false,
      title: 'About',
      description: 'TrackitHub specializes in the management and organization of Premier Pickleball Leagues and Pickleball Clubs around the world.'
    }
  }, {
    path: 'shareteam/:team_id/:rp_id',
    component: HomeComponent,
    data: {
      auth: false,
      title: 'About',
      description: 'TrackitHub specializes in the management and organization of Premier Pickleball Leagues and Pickleball Clubs around the world.'
    }
  }, {
    path: 'league/:league/:ambassador',
    component: HomeComponent,
    data: {
      auth: false,
      title: 'About',
      description: 'TrackitHub specializes in the management and organization of Premier Pickleball Leagues and Pickleball Clubs around the world.'
    }
  }, {
    path: 'showpregame/:pregame',
    component: HomeComponent,
    data: {
      auth: false,
      title: 'About',
      description: 'TrackitHub specializes in the management and organization of Premier Pickleball Leagues and Pickleball Clubs around the world.'
    }
  }, {
    path: 'leagues',
    component: HomeComponent,
    data: {
      auth: false,
      title: 'About',
      description: 'TrackitHub specializes in the management and organization of Premier Pickleball Leagues and Pickleball Clubs around the world.'
    }
  }, {
    path: 'leagues/:ambassador',
    component: HomeComponent,
    data: {
      auth: false,
      title: 'About',
      description: 'TrackitHub specializes in the management and organization of Premier Pickleball Leagues and Pickleball Clubs around the world.'
    }
  }, {
    path: 'attendees',
    component: HomeComponent,
    data: {
      auth: false,
      title: 'About',
      description: 'TrackitHub specializes in the management and organization of Premier Pickleball Leagues and Pickleball Clubs around the world.'
    }
  }, {
    path: 'pregame',
    component: HomeComponent,
    data: {
      auth: false,
      title: 'About',
      description: 'TrackitHub specializes in the management and organization of Premier Pickleball Leagues and Pickleball Clubs around the world.'
    }
  },
  {
    path: 'registeruser/:toleague',
    component: HomeComponent,
    data: {
      auth: false,
      title: 'About',
      description: 'TrackitHub specializes in the management and organization of Premier Pickleball Leagues and Pickleball Clubs around the world.'
    }
  },
  {
    path: 'registerteam/:toleagueteam',
    component: HomeComponent,
    data: {
      auth: false,
      title: 'About',
      description: 'TrackitHub specializes in the management and organization of Premier Pickleball Leagues and Pickleball Clubs around the world.'
    }
  },
  {
    path: 'registerteam/:toleagueteam/:team',
    component: HomeComponent,
    data: {
      auth: false,
      title: 'About',
      description: 'TrackitHub specializes in the management and organization of Premier Pickleball Leagues and Pickleball Clubs around the world.'
    }
  },
  {
    path: 'registerpregame/:toleaguebypass',
    component: HomeComponent,
    data: {
      auth: false,
      title: 'About',
      description: 'TrackitHub specializes in the management and organization of Premier Pickleball Leagues and Pickleball Clubs around the world.'
    }
  },
  {
    path: '',
    component: HomeComponent,
    data: {
      auth: false,
      title: 'About',
      description: 'TrackitHub specializes in the management and organization of Premier Pickleball Leagues and Pickleball Clubs around the world.'
    }
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    data: {
      auth: false,
      title: 'Not Found',
      description: 'Error Page'
    }
  },
  {
    path: 'error-page',
    component: ErrorPageComponent,
    data: {
      auth: false,
      title: 'Error',
      description: 'Error Page'
    }
  },
  {
    path: 'pricing',
    component: PricingComponent,
    data: {
      auth: false,
      title: 'Pricing',
      description: 'Description Meta Tag Content'
    }
  },
  {
    path: 'results',
    component: MapResultsComponent,
    data: {
      auth: false,
      title: 'Search Results',
      description: 'Description Meta Tag Content'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      auth: false,
      title: 'TrackitHub: Login',
      description: ''
    }
  },
  {
    path: 'reset-password',
    component: LoginComponent,
    data: {
      auth: false,
      title: 'TrackitHub: Reset Password',
      description: ''
    }
  },
  {
    path: 'signup',
    component: LoginComponent,
    data: {
      auth: false,
      title: 'TrackitHub: Register',
      description: 'Register for free and setup a Premier Pickleball League Today! '
    }
  },
  {
    path: 'privacy',
    component: PrivacyPolicyComponent,
    data: {
      auth: false,
      title: 'TrackitHub: Privacy Policy',
      description: 'Privacy Policy'
    }
  },
  {
    path: 'terms',
    component: PrivacyPolicyComponent,
    data: {
      auth: false,
      title: 'TrackitHub: Terms of Use',
      description: 'Terms of Use'
    }
  },
  {
    path: 'disclaimer',
    component: PrivacyPolicyComponent,
    data: {
      auth: false,
      title: 'TrackitHub: Disclaimer',
      description: 'Disclaimer'
    }
  },
  {
    path: 'register/:id',
    component: RegisterComponent,
    data: {
      auth: false,
      title: 'Register',
      description: 'Register for free'
    }
  },
  {
    path: 'dashboard/overview',
    component: DashboardOverviewComponent,
    data: {
      title: 'Dashboard',
      description: 'We are simplifying the way you play pickleball. The Dashboard is a type of user interface which provides at-a-glance views of events, standings, membership, and much more. All of your information is right at your fingertips. Explore the fresh, user-friendly interface which is simplifying the way you play pickleball.',
      auth: true,
    }
  }, {
    path: 'dashboard/openmatches/:id',
    component: DashboardOverviewComponent,
    data: {
      title: 'Dashboard Trial Admin',
      description: 'We are simplifying the way you play pickleball. The Dashboard is a type of user interface which provides at-a-glance views of events, standings, membership, and much more. All of your information is right at your fingertips. Explore the fresh, user-friendly interface which is simplifying the way you play pickleball.',
      auth: true,
    }
  },{
    path: 'dashboard/trialadmin',
    component: DashboardTrialAdminComponent,
    data: {
      title: 'Dashboard Trial Admin',
      description: 'We are simplifying the way you play pickleball. The Dashboard is a type of user interface which provides at-a-glance views of events, standings, membership, and much more. All of your information is right at your fingertips. Explore the fresh, user-friendly interface which is simplifying the way you play pickleball.',
      auth: true,
    }
  },
  {
    path: 'dashboard/subadmin',
    component: DashboardSubAdminComponent,
    data: {
      title: 'Dashboard Subscription Admin',
      description: 'We are simplifying the way you play pickleball. The Dashboard is a type of user interface which provides at-a-glance views of events, standings, membership, and much more. All of your information is right at your fingertips. Explore the fresh, user-friendly interface which is simplifying the way you play pickleball.',
      auth: true,
    }
  },
  {
    path: 'dashboard/frame/:id',
    component: DashboardFrameComponent,
    data: {
      auth: true,
      title: 'TrackitHub: Register',
      description: 'Register for free'
    }
  },
  {
    path: 'dashboard/editevent/:id',
    component: DashboardEditeventComponent,
    data: {
      auth: true,
      title: 'TrackitHub: Register',
      description: 'Register for free'
    }
  },
  {
    path: 'dashboard/copyevent/:id',
    component: DashboardCopyeventComponent,
    data: {
      auth: true,
      title: 'TrackitHub: Register',
      description: 'Register for free'
    }
  },
  {
    path: 'dashboard/editrepeat/:id',
    component: DashboardEditrepeatComponent,
    data: {
      auth: true,
      title: 'TrackitHub: Register',
      description: 'Register for free'
    }
  },
  {
    path: 'dashboard/viewevent/:id',
    component: DashboardVieweventComponent,
    data: {
      auth: true,
      title: 'TrackitHub: Register',
      description: 'Register for free'
    }
  },
  {
    path: 'dashboard/viewrepeat/:id',
    component: DashboardViewrepeateventComponent,
    data: {
      auth: true,
      title: 'TrackitHub: Register',
      description: 'Register for free'
    }
  },
  {
    path: 'dashboard/profile/:id',
    component: DashboardProfileComponent,
    data: {
      auth: true,
      title: 'TrackitHub: Register',
      description: 'Register for free'
    }
  },
  {
    path: 'dashboard/groups/:id',
    component: DashboardGroupsComponent,
    data: {
      auth: true,
      title: 'TrackitHub: Register',
      description: 'Register for free'
    }
  },
  {
    path: 'dashboard/import/:id',
    component: DashboardImportComponent,
    data: {
      auth: true,
      title: 'TrackitHub: Register',
      description: 'Register for free'
    }
  },
  {
    path: 'dashboard/membershipadmin',
    component: DashboardMembershipadminComponent,
    data: {
      auth: true,
      title: 'TrackitHub: Register',
      description: 'Register for free'
    }
  },
  {
    path: 'dashboard/editgroup/:id',
    component: DashboardEditgroupComponent,
    data: {
      auth: true,
      title: 'TrackitHub: Register',
      description: 'Register for free'
    }
  },
  {
    path: 'dashboard/photos/:id',
    component: DashboardPhotosComponent,
    data: {
      auth: true,
      title: 'TrackitHub: Register',
      description: 'Register for free'
    }
  },
  {
    path: 'dashboard/map/:type',
    component: DashboardMapComponent,
    data: {
      auth: true,
      title: 'TrackitHub: Register',
      description: 'Register for free'
    }
  },
  {
    path: 'dashboard/map',
    component: DashboardMapComponent,
    data: {
      auth: true,
      title: 'TrackitHub: Register',
      description: 'Register for free'
    }
  },
  {
    path: 'dashboard/announcements/:id',
    component: DashboardAnnouncementsComponent,
    data: {
      auth: true,
      title: 'TrackitHub: Register',
      description: 'Register for free'
    }
  },
  {
    path: 'dashboard/settings',
    component: DashboardSettingsComponent,
    data: {
      auth: true,
      title: 'Settings',
      description: 'Change settings here'
    }
  },
  {
    path: 'dashboard/account-settings',
    component: AccountSettingsComponent,
    data: {
      title: 'Account Settings',
      description: 'Associate multiple profiles to one email address, purchase your TrackitHub subscription, edit basic information, or revise your notification/privacy settings.',
      auth: true,
    }
  },
  {
    path: 'dashboard/payment-settings',
    component: PaymentSettingsComponent,
    data: {
      title: 'Payment Settings',
      description: '',
      auth: true,
    }
  },
  {
    path: 'dashboard/calendar',
    component: EventsCalendarFullComponent,
    data: {
      title: 'Events Calendar',
      description: '',
      auth: true,
    }
  },
  {
    path: 'dashboard/standings-results',
    component: StandingsResultsComponent,
    data: {
      title: 'Standings Results',
      description: '',
      auth: true,
    }
  },
  {
    path: 'dashboard/standings-scorecard',
    component: StandingsScorecardComponent,
    data: {
      title: 'Standings',
      description: '',
      auth: true,
    }
  },
  {
    path: 'dashboard/standings-table',
    component: StandingsTableComponent,
    data: {
      title: 'Standings',
      description: '',
      auth: true,
    }
  },
  {
    path: 'dashboard/roster',
    component: RosterTableComponent,
    data: {
      title: 'Events Calendar',
      description: '',
      auth: true,
    }
  },
  {
    path: 'dashboard/ambassador',
    component: AmbassadorTableComponent,
    data: {
      title: 'Events Calendar',
      description: '',
      auth: true,
    }
  },
  {
    path: 'dashboard/standings',
    component: DashboardStandingsComponent,
    data: {
      title: 'Events Calendar',
      description: '',
      auth: true,
    }
  },
];
export const appRoutingModule: ModuleWithProviders = RouterModule.forRoot(routes, {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'reload'
});
