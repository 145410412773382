import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit, Renderer2,
  ViewChild, ViewContainerRef
} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {FieldConfig} from '../../dynamic-form/models/field-config.interface';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {AccountService} from '../../common/services/account.service';
import {HttpClient} from '@angular/common/http';
import {PanelService} from '../../common/services/panel.service';
import {ToastService} from '../../common/toast';
import {TourService} from 'ngx-tour-md-menu';
import {Account} from '../../common/interfaces/account';
import {IStepOption} from 'ngx-tour-core';
import {MatDialog, MatSidenav} from '@angular/material';
import {BottomSheetService} from '../../common/services/bottom-sheet.service';
import {
  DashboardPurchaseSupportModalComponent,
  DashboardCreateGroupComponent,
  DashboardJoinGroupsComponent,
  DashboardSubscribeComponent,
  DashboardShowtrialComponent,
  DashboardShowSuccessComponent,
  DashboardFriendsComponent
} from '../../dashboard';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalService} from '../../common/services/modal.service';
import {EventsCalendarFullComponent} from '../../events';
import {CalendarService} from '../../common/services/calendar.service';
import {DashboardViewprofileComponent} from '../dashboard-viewprofile/dashboard-viewprofile.component';
import {environment} from '../../../environments/environment';
import {DynamicComponentService} from '../../common/services/dynamic-component.service';
import {PickleballPlaceDetailsComponent} from "../../frontend";
import { DashboardSettingsComponent } from '../dashboard-settings/dashboard-settings.component';
import { AlertComponent } from 'src/app/common/components/alert/alert.component';
import { ProfileComponent } from 'src/app/profile/profile.component';
import {SessionStorageService} from "ngx-webstorage";
import {SearchAddressService} from "../../common/services/searchaddress.service";
import {ChatService} from "../../common/services/chat.service";
import {DashboardMyleaguesComponent} from "../../dashboard/dashboard-myleagues/dashboard-myleagues.component";
import {LeaguesComponent} from "../../frontend/leagues/leagues.component";


export interface AccountInterface {
  id: number;
  accountname: string;
}

@Component({
  selector: 'app-dashboard-nav',
  templateUrl: './dashboard-nav.component.html',
  styleUrls: ['./dashboard-nav.component.scss'],
  host: {
    "(window:resize)":"onWindowResize($event)"
  },
  changeDetection: ChangeDetectionStrategy.Default,
})

export class DashboardNavComponent implements OnInit, AfterViewInit, OnDestroy {
  dashboardRoute = 'dashboard/overview';
  isZen = false;
  env: any;
  s1 = null;
  timer = null;
  trigger = null;
  announcement = ""



  goingsub = false;


  private fragment: string;

  constructor(
    private calendarService: CalendarService,
    private modalService: ModalService,
    private accountService: AccountService,
    private breakpointObserver: BreakpointObserver,
    private panelService: PanelService,
    private http: HttpClient,
    private toastService: ToastService,
    public tourService: TourService,
    private bottomSheetService: BottomSheetService,
    private router: Router,
    private route: ActivatedRoute,
    private service: DynamicComponentService,
    public dialog: MatDialog,
    private sessionSt: SessionStorageService,
    private SearchAddress: SearchAddressService,
    private  chatService: ChatService,
    private renderer: Renderer2
  ) {
    this.env = environment;
    this.tourService.initialize([
      {
        anchorId: 'welcome',
        title: ' Take It for a Spin',
        content: 'Orient yourself with this quick tour. Learn where to find key features, so you can begin taking advantage of the powerful new TrackitHub.',
        preventScrolling: false,
      },
      {
        stepId: 'search',
        anchorId: 'search',
        title: 'Search',
        content: 'Search for clubs, leagues, and facilities to join or make friends with new players.',
        preventScrolling: false,
      },
      {
        stepId: 'events',
        anchorId: 'events',
        title: 'Events Calendar',
        content: 'View and register for events that you have been invited to.',
        preventScrolling: false,
      },
      {
        anchorId: 'account-settings-menu',
        title: 'Account Settings Menu',
        content: 'Edit basic info, change password, or log out of your account.',
        preventScrolling: false
      },
      {
        stepId: 'profile-menu',
        anchorId: 'profile-menu',
        title: 'Profile Menu',
        content: 'Select arrow next to profile picture to create new or switch between profiles.',
        preventScrolling: false
      },
      {
        stepId: 'profile',
        anchorId: 'profile',
        title: 'Profile',
        content: 'Access your profile by clicking on your name.',
        preventScrolling: false
      },
      {
        stepId: 'help',
        anchorId: 'help',
        title: 'Need Help?',
        content: 'Search our extensive knowledge articles for information regarding tools and features.',
        preventScrolling: false
      }
    ]);
  }

  @ViewChild('dynamic', {static: false, read: ViewContainerRef}) view: ViewContainerRef;
  @ViewChild('appDrawer', {static: true}) appDrawer: ElementRef;
  @ViewChild('drawer', {static: true}) drawer: MatSidenav;

  isMobile: boolean = false;
  width: number = window.innerWidth;
  mobileWidth: number  = 590;

  account: Account;
  MenuItems = [];
  userSearch: FieldConfig[] = [
    {
      type: 'input',
      name: 'filter',
      placeholder: 'Filter',
      required: false,
    },
  ];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  // ---
  // PRIVATE METHODS.
  // ---


  // I return an Observable stream of values that should be used to drive the
  // visibility of the Zendesk widget.

  private static getZendeskStatusStream(): Observable<boolean> {

    return of(true, false);

  }

  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.isMobile = this.width < this.mobileWidth;
  }

  showToast() {
    this.toastService.show({
      text: `Please purchase a subscription in order to join a club or league.`,
      type: 'warn',
      buttonText: 'Subscribe',
    });
  }

  standingsJoin() {
    this.toastService.show({
      text: `You must join a club or league before viewing the Standings page.`,
      type: 'warn',
      buttonText: 'Join',
    });
  }

  openSubscriptionPanel() {
    if(this.isMobile) {
      this.drawer.close();
    }
    this.service.setRootViewContainerRef(this.view);
    this.service.reset();
    this.service.insertComponent(DashboardSubscribeComponent, this.panelService.appDrawer);
    this.panelService.openPanel(DashboardSubscribeComponent,'DashboardSubscribeComponent');
  }

  eventRemoved() {
    this.toastService.show({
      text: `The event you are trying to view has been removed. Please contact your club admin for additional information.`,
      type: 'alert',
    });
  }

  duesReminder() {
    this.toastService.show({
      text: `Your club membership dues are about to expire.`,
      type: 'alert',
      buttonText: 'Pay Dues',
      buttonColor: 'primary',
      buttonURL: ''
    });
  }

  beginTour() {
    if (localStorage.getItem('tour') === 'true') {
      this.tourService.start();
      localStorage.setItem('tour', 'false');
    } else {
      this.tourService.end();
      localStorage.setItem('tour', 'false');
    }
  }

  ngOnInit(): void {


    if(this.timer){
      this.timer.unsubscribe;
    }

    this.isMobile = this.width < this.mobileWidth;

      // rsudduth re visit this.   it opens chat
      //this.timer = setInterval(() => this.checkChat(), 15000);


    this.isMobile = this.width < this.mobileWidth;

    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;



      //this.checkChat();


      if (data.id !== null) {
        this.MenuItems = data.menuItems;
        this.trigger = this.route.snapshot.queryParamMap.get('trigger');
        if (this.trigger == 'submonth') {
          this.service.setRootViewContainerRef(this.view);
          this.service.reset();
          this.service.insertComponent(DashboardSubscribeComponent, this.panelService.appDrawer);
          this.panelService.openPanel(DashboardSubscribeComponent,'DashboardSubscribeComponent');
        }
        if (this.trigger == 'subyear') {
          this.service.setRootViewContainerRef(this.view);
          this.service.reset();
          this.service.insertComponent(DashboardSubscribeComponent, this.panelService.appDrawer);
          this.panelService.openPanel(DashboardSubscribeComponent,'DashboardSubscribeComponent');
        }
        if (this.trigger == 'success') {
          this.modalService.openDialog(DashboardShowSuccessComponent, 'auto', 'auto', {ref: this.modalService});
        }
        if (this.trigger == 'showsub') {
          if(this.isMobile) {
            this.drawer.close();
          }
          this.service.setRootViewContainerRef(this.view);
          this.service.reset();
          this.service.insertComponent(DashboardSubscribeComponent, this.panelService.appDrawer);
          this.panelService.openPanel(DashboardSubscribeComponent,'DashboardSubscribeComponent');
        } else {
          if (this.trigger != 'submonth' && this.trigger != 'subyear') {
            if (data.current_profile.show_trial == true) {
              this.service.setRootViewContainerRef(this.view);
              this.modalService.openDialog(DashboardShowtrialComponent, 'auto', 'auto', {ref: this.modalService});
              this.service.reset();
              this.service.insertComponent(PickleballPlaceDetailsComponent, this.panelService.appDrawer);
            } else {
              this.service.setRootViewContainerRef(this.view);
              this.service.reset();
              this.service.insertComponent(PickleballPlaceDetailsComponent, this.panelService.appDrawer);
            }
          }
        }
      }
    });


    // this.openAlert(this.announcement);


  }

  checkChat(){
    if(this.account){
        this.http.get<any>(environment.service.trackithubapi + '/api/inside/lastchatcheck?from_id=' + this.account.current_profile.profile_id).subscribe((data: any) => {
          if(data.result != 'fail') {





            if ((localStorage.getItem('last_chat') != data.profile_id) || (localStorage.getItem('last_unread') != data.last_chat)) {


              if(localStorage.getItem('last_unread') == '' && localStorage.getItem('last_chat') == '') {

              } else {


                if(!this.isMobile) {

                  this.chatService.openchatuser({
                    profile_id: data.profile_id,
                    name: data.name,
                    avatarImg: data.avatarImg,
                    avatarPlaceholder: data.avatarPlaceholder,
                    email: data.email
                  });
                }
              }
              localStorage.setItem('last_chat', data.profile_id);
              localStorage.setItem('last_unread', data.last_chat);

            } else {

            }
          } else {

          }
        });
    }
  }

  openProfile(profile_id, profile_type) {
    if(this.env.service.profile == true) {
      this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + profile_id).subscribe((profileData: any) => {
        if(this.isMobile) {
          this.drawer.close();
        }
        this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
          'profileData': profileData,
          'type': profile_type
        }, 'custom-bottom-p' );
      });
    }
  }

  branchandload(type, event, url) {
    event.preventDefault();
    switch (type) {
      case 'Create Group': {
        this.bottomSheetService.openBottomSheetWithData(DashboardCreateGroupComponent, 'DashboardCreateGroupComponent',{'id': 0});
        break;
      }
      case 'My Groups': {
        if (this.account.current_profile.is_trial === false && this.env.service.subscription === true && this.account.current_profile.subscription === false){
          this.openSubscriptionPanel();
        } else {
          this.bottomSheetService.openBottomSheetWithData(DashboardJoinGroupsComponent, 'DashboardJoinGroupsComponent','');
        }
        break;
      }

      case 'Matches': {
        this.bottomSheetService.openBottomSheetWithData(DashboardMyleaguesComponent, 'DashboardMyleaguesComponent','');
        break;
      }

      case 'Find Leagues': {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(LeaguesComponent, 'LeaguesComponent',{}, 'custom-bottom-p');
        break;
      }

      case 'My Leagues': {
        this.bottomSheetService.openBottomSheetWithData(DashboardJoinGroupsComponent, 'DashboardJoinGroupsComponent','');
        break;
      }
      case 'Purchase Support': {
        this.modalService.openDialog(DashboardPurchaseSupportModalComponent, 'auto', 'auto', {});
        break;
      }
      case 'Calendar': {
        if (this.account.current_profile.is_trial === false && this.env.service.subscription === true && this.account.current_profile.subscription === false){
          this.openSubscriptionPanel();
        } else {
          this.modalService.openDialog(EventsCalendarFullComponent, '80%', 'auto', {ref: this.modalService});
        }
        break;
      }


      case 'Find Players': {
        if (this.account.current_profile.is_trial === false && this.env.service.subscription === true && this.account.current_profile.subscription === false){
          this.openSubscriptionPanel();
        } else {
          this.bottomSheetService.openBottomSheetWithData(DashboardFriendsComponent, 'DashboardFriendsComponent','');
        }
        break;
      }

      case 'Find Clubs': {
        if (this.account.current_profile.is_trial === false && this.env.service.subscription === true && this.account.current_profile.subscription === false){
          this.openSubscriptionPanel();
        } else {
          this.sessionSt.store('play_type', 'Clubs');
          this.SearchAddress.forcesearch();
          this.router.navigate(['/dashboard/map'], {queryParams: {}});
        }
        break;
      }



      case 'Knowledge Base': {
        window.open('https://trackithub.zendesk.com/', "_blank");
      }

      default: {
        if (this.account.current_profile.is_trial === false && this.env.service.subscription === true && this.account.current_profile.subscription === false){
          this.openSubscriptionPanel();
        } else {
          document.location.href = url;
        }
        break;
      }
    }
  }

  openNewTab(url: string): void {
    const newTab = this.renderer.createElement('a');
    this.renderer.setAttribute(newTab, 'href', url);
    this.renderer.setAttribute(newTab, 'target', '_blank');
    newTab.click();
    this.renderer.removeChild(document.body, newTab);
  }

  viewprofile(profile_id, profile_name) {
    this.bottomSheetService.openBottomSheetWithData(DashboardViewprofileComponent, 'DashboardViewprofileComponent', {'profile_id': profile_id, 'profile_name': profile_name});
  }




  openAlert(announcement: string) {
    announcement = this.announcement;
    this.dialog.open(AlertComponent, {
      data: {
        announcement: announcement,
      },
    });
  }




  ngAfterViewInit() {



    this.panelService.appDrawer = this.appDrawer;
    this.beginTour();

    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small])
      .subscribe((state: BreakpointState) => {
        this.tourService.stepShow$.subscribe((step: IStepOption) => {
          if (step.anchorId === 'welcome') { // or step.anchorId === step2.anchorId
            this.drawer.close().then();
          }
          if (step.anchorId === 'account-settings-menu') {
            this.drawer.open().then();
          }
          if (step.anchorId === 'events') {
            document.querySelector('#eventcalendar').scrollIntoView();
          }
        });
        this.tourService.end$.subscribe(data => {
          this.drawer.open().then();
        });
      }).unsubscribe();




  }

  openSettings() {
    this.bottomSheetService.openBottomSheetWithData(DashboardSettingsComponent, 'DashboardSettingsComponent','');
  }

  triggerClose() {


    if (this.trigger == 'submonth' || this.trigger == 'subyear') {
      window.location.href = 'dashboard/overview';

    }

    this.panelService.appDrawer.close();
    this.service.setRootViewContainerRef(this.view);
    this.service.reset();
    this.service.insertComponent(PickleballPlaceDetailsComponent, this.panelService.appDrawer);
    this.panelService.windowScrolling.enable();


  }



  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
    if (this.timer) {
      this.timer.unsubscribe();
    }
  }

}
