
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Inject,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders } from '@angular/common/http';
import {AccountService} from '../../common/services/account.service';
import {CalendarService} from '../../common/services/calendar.service';
import { DashboardContactSheetComponent } from '../dashboard-contact-sheet/dashboard-contact-sheet.component';
import {BottomSheetService} from '../../common/services/bottom-sheet.service';
import {ToastService} from '../../common/toast';
import {Router} from '@angular/router';
import {SelectionModel} from '@angular/cdk/collections';
import {DialogData, RosterElement} from '../dashboard-roster/roster-table.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import {ModalService} from '../../common/services/modal.service';
import {Account} from "../../common/interfaces/account";
import {ProfileComponent} from "../../profile/profile.component";
import {
  PayPalScriptService,
  IPayPalConfig,
  ICreateOrderRequest,
  IPayPalUrlConfig,
  ICreateSubscriptionRequest, ICreateSubscriptionCallbackActions, ICreateSubscriptionCallbackData
} from 'ngx-paypal';
import { MatTabGroup } from '@angular/material/tabs';
import {myCustomIPayPalConfig} from "../dashboard-subscribe/dashboard-subscribe.component";
import {DashboardShowSuccessComponent} from "../dashboard-showsuccess/dashboard-showsuccess.component";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";



export interface EventViewElement {
  name: string;
  status: string;
  id: number;
  profile_id: string;
  waiting: string;
  paid: string;
  owed: string;
  totalprice: string;
  fee: string;
  guestcount: string;
  gateway: string;
}

export interface InviteViewElement {
  attendees: string;
  attendstate: string;
  confirmed: string;
  emailsent: string;
  id: number;
  invite_id: number;
  name: string;
  viewed: string;
  waiting: string;
}


@Component({
  selector: 'app-dashboard-eventview',
  templateUrl: './dashboard-eventview.component.html',
  styleUrls: ['./dashboard-eventview.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})



export class DashboardEventviewComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('tabGroup',{ static: true }) tabGroup: MatTabGroup;

  public payPalConfig?: myCustomIPayPalConfig;
  public payPalConfig2?: myCustomIPayPalConfig;
  public payPalUrl?: IPayPalUrlConfig;

  api_key: any;

  env: any;
  guestcount: number;
  emailsent: boolean;
  sheetRef: any = this.data.ref;
  eventdetail: any = {
    'guestcount': 0,
    'billing_state': null,
    'paid': null,
    'owed': null,
    'withstanding': null,
    'overrideprice': null,
    'ev_id': null,
    'catid': null,
    'attendance_id': null,
    'summary': null,
    'description': null,
    'event_type': null,
    'dynamic_rp': null,
    'last_repeat': null,
    'next_repeat': null,
    'invite_id': null,
    'capacity': null,
    'waitingcapacity': null,
    'attending': null,
    'group_id': null,
    'repeat_id': null,
    'permissions': null,
    'invite_status': null,
    'startrepeat': null,
    'endrepeat': null,
    'date_start': null,
    'date_starts': null,
    'start_time': null,
    'time_stop': null,
    'reg_close': null,
    'cancel_close': null,
    'cancel_status': null,
    'reg_open': null,
    'created_by': null,
    'register_status': null,
    'confirmed': null,
    'waiting': null,
    'attendstate': null,
    'city': null,
    'state': null,
    'geolon': null,
    'geolat': null,
    'street': null,
    'postcode': null,
    'title': null,
    'event_started': null,
    'is_owner': null,
    'is_admin': null,
    'is_league' : null,
    'start_day': null,
    'start_dates': null,
    'attendance_list': [],
    'invite_list': [],
    'invitelist': [],
    'grouplist': [],
    'published': null,
    'overall_status': null,
    'custom_rsvp_message': null
  };

  content: SafeHtml;
  dateobj: any;
  account: any;
  calendar: any;
  isPaypal = false;
  isPaypal2 = false;

  smallScreen: boolean;
  bigScreen: boolean;

  isover: boolean;
  s1: any;
  s2: any;
  isDisabled: boolean = false;


  listdata: EventViewElement[] = [{
    name: '',
    status: '',
    waiting: '',
    paid: '',
    owed: '',
    totalprice: '',
    fee: '',
    guestcount: '',
    id: null,
    profile_id: '',
    gateway: ''
  }];

  invitedata: InviteViewElement[] = [{
    attendees: '1',
    attendstate: '1',
    confirmed: '1',
    emailsent: '0',
    id: 17328,
    invite_id: 4207697,
    name: 'Test Three',
    viewed: '1',
    waiting: '0'
  }];

  fake: boolean;

  displayedColumns: string[] = ['name', 'status'];
  displayedColumns2: string[] = ['name', 'viewed', 'emailsent', 'confirmed', 'delete' ];

  selection = new SelectionModel<InviteViewElement>(true, []);
  selection2 = new SelectionModel<InviteViewElement>(true, []);


  dataSource = new MatTableDataSource();
  dataSource2 = new MatTableDataSource();

  private httpOptions = {
    headers: new HttpHeaders({
      'Accept': 'text/html, application/xhtml+xml, */*',
      'Content-Type': 'application/x-www-form-urlencoded'
    }),
    responseType: 'text'
  };

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild('MatSort', {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<EventViewElement>;

  @ViewChild('MatSort2', {static: false}) sort2: MatSort;
  @ViewChild(MatTable, {static: false}) table2: MatTable<InviteViewElement>;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private breakpointObserver: BreakpointObserver, public dialog: MatDialog, private modalService: ModalService, private router: Router, private toastService: ToastService, private sanitizer: DomSanitizer,  private http: HttpClient, private calendarService: CalendarService, private accountService: AccountService, private ref: ChangeDetectorRef, private bottomSheetService: BottomSheetService) {
    breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).subscribe(result => {
      this.smallScreen = result.matches;
      this.bigScreen = !result.matches;

      console.log('xxxxx',this.smallScreen, this.bigScreen);


    });
  }

  ngOnInit() {

    this.env = environment;


    this.fake = false;

    this.guestcount = 1;

    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
    });

    this.s2 = this.calendarService.cast.subscribe( data => {
      this.calendar = data;
    });


    console.log(this.data.id.tab);







    if (this.data.id.event != null && this.data.id.repeat_id != null) {
      this.http.get<Account>(environment.service.trackithubapi + '/api/inside/event_owner?event=' + this.data.id.event).subscribe((data: any) => {
        console.log(data);
        switch (data.ownerid) {
          case '3188': {
            this.api_key = 'AezGsn5DSG2hNRx63M55-k96s0q9VszPPLKm2ZDXjkhnBCRXY8hhwqmPOimpy-CyH91RjvFt4lxjzkkK'
            break;
          }
          case '51032': {
            this.api_key = 'ARfpZ-gXTHNTrJ1W4tu8rb7An09kAh3uF_jaBfr7SwBe0vu2-iLnzBTVJ9dYNq_5KfJakRvYPYnuJsU0';
            break;
          }
          default: {
            this.api_key = '';
            break;
          }
        }
        this.gotorepeat(this.data.id.event, this.data.id.repeat_id);
        this.payPalConfig = null;
        this.payPalConfig2 = null;
      });
    } else {

      this.http.get<Account>(environment.service.trackithubapi + '/api/inside/jomhub_owner?jom_hub=' + this.data.id.eventdetail.jom_hub).subscribe((data: any) => {
        console.log(data);

        switch (data.ownerid) {
          case '3188': {
            this.api_key = 'AezGsn5DSG2hNRx63M55-k96s0q9VszPPLKm2ZDXjkhnBCRXY8hhwqmPOimpy-CyH91RjvFt4lxjzkkK'
            break;
          }
          case '51032': {
            this.api_key = 'ARfpZ-gXTHNTrJ1W4tu8rb7An09kAh3uF_jaBfr7SwBe0vu2-iLnzBTVJ9dYNq_5KfJakRvYPYnuJsU0';
            break;
          }
          default: {
            this.api_key = '';
            break;
          }
        }

        this.eventdetail = this.data.id.eventdetail;
        this.payPalConfig = null;
        this.payPalConfig2 = null;

        this.paypalconfig();
        this.paypalconfig2();
        this.ready();
      });
    }

  }



  public openpaypal(){
    this.isPaypal = !this.isPaypal;
    this.payPalConfig = null;
    this.paypalconfig();
  }

  public openpaypal2(){
    this.isPaypal2 = !this.isPaypal2;
    this.payPalConfig2 = null;
    this.paypalconfig2();
  }


  public paypalconfig() {


      this.payPalConfig = {
      //clientId: 'AXud3_cRCn_NdVCbvppfPbMpS2WUy8E-vECdaCGbe0DuvuZ6jl6Zzs7qPT3x3F7NREp-LlGuMXXf12et',
      clientId: this.api_key,
      currency: 'USD',
      vault: 'true',
      style: {
        label: 'paypal',
        layout: 'vertical',
        size: 'small',
        shape: 'pill',
        color: 'silver',
        tagline: false,
      },

      createOrderOnClient: (data) => <ICreateOrderRequest> {
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            currency_code: 'USD',
            value: this.eventdetail.overrideprice,
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: this.eventdetail.overrideprice
              }
            }
          },
          items: [
            {
              name: this.eventdetail.summary,
              quantity: "1",
              unit_amount: {
                currency_code: "USD",
                value: this.eventdetail.overrideprice,
              },
            },
          ],
        }]
      },
      advanced: {
        commit: 'true'
      },
      onApprove: (data, actions) => {

        actions.order.get().then(details => {

          const headers = new HttpHeaders({       'Accept': 'text/html'});
          const input = new FormData();

          this.http.post(environment.service.trackithubapi + '/api/inside/useracceptinvite?pay='+ this.eventdetail.overrideprice + '&profile_id='+this.account.current_profile.profile_id+'&at_id='+this.eventdetail.attendance_id+'&rp_id='+this.eventdetail.repeat_id, input, {headers} ).subscribe((data: any) => { }, error => {
            this.toastService.show({
              text: 'Attendance has been updated.',
              type: 'success',
            });

            this.gotorepeat(event, this.eventdetail.repeat_id);

          });
        });
      },
      onClientAuthorization: (data) => {

        //console.log("meco");

        //this.recordAction();
      },
      onCancel: (data, actions) => {
        this.toastService.show({
          text: 'You have canceled your order.',
          type: 'warn',
        });
      },
      onError: err => {
        this.toastService.show({
          text: 'There has been an error please contact support.',
          type: 'warn',
        });
      },
      onClick: (data, actions) => {

      },
    };

  }
  public paypalconfig2() {


      this.payPalConfig2 = {
        //clientId: 'AXud3_cRCn_NdVCbvppfPbMpS2WUy8E-vECdaCGbe0DuvuZ6jl6Zzs7qPT3x3F7NREp-LlGuMXXf12et',
        clientId: this.api_key,
        currency: 'USD',
        vault: 'true',
        style: {
          label: 'paypal',
          layout: 'vertical',
          size: 'small',
          shape: 'pill',
          color: 'silver',
          tagline: false,
        },

        createOrderOnClient: (data) => <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [{
            amount: {
              currency_code: 'USD',
              value: this.eventdetail.overrideprice,
              breakdown: {
                item_total: {
                  currency_code: 'USD',
                  value: this.eventdetail.overrideprice
                }
              }
            },

            items: [
              {
                name: this.eventdetail.summary,
                quantity: "1",
                unit_amount: {
                  currency_code: "USD",
                  value: this.eventdetail.overrideprice,
                },
              },
            ],
          }]
        },
        advanced: {
          commit: 'true'
        },
        onApprove: (data, actions) => {

          actions.order.get().then(details => {

            const headers = new HttpHeaders({'Accept': 'text/html'});
            const input = new FormData();

            this.http.post(environment.service.trackithubapi + '/api/inside/userpayinvite?pay=' + this.eventdetail.overrideprice + '&profile_id=' + this.account.current_profile.profile_id + '&at_id=' + this.eventdetail.attendance_id + '&rp_id=' + this.eventdetail.repeat_id, input, {headers}).subscribe((data: any) => {
            }, error => {
              this.toastService.show({
                text: 'Attendance has been updated.',
                type: 'success',
              });

              this.gotorepeat(event, this.eventdetail.repeat_id);

            });
          });
        },
        onClientAuthorization: (data) => {


        },
        onCancel: (data, actions) => {
          this.toastService.show({
            text: 'You have canceled your order.',
            type: 'warn',
          });
        },
        onError: err => {
          this.toastService.show({
            text: 'There has been an error please contact support.',
            type: 'warn',
          });
        },
        onClick: (data, actions) => {

        },
      };

  }
  export(ev_id, at_id, repeat) {
    window.location.href = '/api/inside/exportattendee?at_id=' + at_id + '&rp_id=' + repeat;
  }

  copyevent(ev_id, at_id, repeat, realrepeat){
    this.router.navigate(['/dashboard/copyevent/' + ev_id]);
    this.sheetRef.dismiss();
  }

  copyrepeat(ev_id, at_id, repeat, realrepeat) {
    this.router.navigate(['/dashboard/copyevent/' + ev_id + '?rp=' + realrepeat]);
    this.sheetRef.dismiss();
  }

  openProfile(profile_id, profile_type) {
    if(this.env.service.profile == true) {
      this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + profile_id).subscribe((profileData: any) => {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
          'profileData': profileData,
          'type': profile_type
        }, 'custom-bottom-p' );

      });
    }
  }

  deletefuture(ev_id, at_id, repeat, realrepeat) {
    const headers = new HttpHeaders({       'Accept': 'text/html'});
    this.http.get(environment.service.trackithubapi + '/index.php?option=com_jevents&task=icalrepeat.deletefuture&cid=' + realrepeat, {headers}).subscribe((data: any) => { }, error => {
      this.gotorepeat(at_id, realrepeat);
      this.toastService.show({
        text: 'Future repeats deleted.',
        type: 'success',
      });
      this.calendarService.reload(this.calendar.group_id, this.calendar.profile_id, this.calendar.datestring);
      this.sheetRef.dismiss();
    });
  }

  deleterepeat(ev_id, at_id, repeat, realrepeat){
    const headers = new HttpHeaders({       'Accept': 'text/html'});
    this.http.get(environment.service.trackithubapi + '/index.php?option=com_jevents&task=icalrepeat.delete&cid=' + realrepeat, {headers}).subscribe((data: any) => { }, error => {
      this.gotorepeat(at_id, realrepeat);
      this.toastService.show({
        text: 'Repeat has been deleted.',
        type: 'success',
      });
      this.calendarService.reload(this.calendar.group_id, this.calendar.profile_id, this.calendar.datestring);
      this.sheetRef.dismiss();
    });
  }

  deleteevent(ev_id, at_id, repeat, realrepeat){
    const headers = new HttpHeaders({       'Accept': 'text/html'});
    this.http.get(environment.service.trackithubapi + '/index.php?option=com_jevents&task=icalevent.delete&evid=' + ev_id, {headers}).subscribe((data: any) => { }, error => {
      this.gotorepeat(at_id, realrepeat);
      this.toastService.show({
        text: 'Event has been deleted.',
        type: 'success',
      });
      this.calendarService.reload(this.calendar.group_id, this.calendar.profile_id, this.calendar.datestring);
      this.sheetRef.dismiss();
    });
  }

  unpublishevent(ev_id, at_id, repeat, realrepeat){
    const headers = new HttpHeaders({       'Accept': 'text/html'});
    this.http.get(environment.service.trackithubapi + '/index.php?option=com_jevents&task=icalevent.unpublish&cid[]=' + ev_id, {headers}).subscribe((data: any) => { }, error => {
      this.gotorepeat(at_id, realrepeat);
      this.toastService.show({
        text: 'Event has been unpublished.',
        type: 'success',
      });
    });
  }

  publishevent(ev_id, at_id, repeat, realrepeat){
    const headers = new HttpHeaders({       'Accept': 'text/html'});
    this.http.get(environment.service.trackithubapi + '/index.php?option=com_jevents&task=icalevent.publish&cid[]=' + ev_id, {headers}).subscribe((data: any) => { }, error => {
      this.gotorepeat(at_id, realrepeat);
      this.toastService.show({
        text: 'Event has been published.',
        type: 'success',
      });
    });
  }


  confirmselected(ev_id, at_id, repeat, realrepeat){


    this.isDisabled = true;


    this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/adminconfirminvite',  {
      'ev_id': ev_id,
      'at_id': at_id,
      'rp_id': repeat,
      'value': JSON.stringify(this.selection.selected)}, { }).subscribe((data: any) => {


        if(data.total == 0){
          this.gotorepeat(at_id, realrepeat);
          this.toastService.show({
            text: 'Not all of the players selected were added to the attendee list due to the capacity limit.',
            type: 'warn',
          });
          this.selection.clear();

        } else {
          this.gotorepeat(at_id, realrepeat);
          this.toastService.show({
            text: 'Attendees have been confirmed.',
            type: 'success',
          });
          this.selection.clear();
        }







    });
  }

  deleteselected(ev_id, at_id, repeat, realrepeat) {



    this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/deleteinvite',  {
      'ev_id': ev_id,
      'at_id': at_id,
      'repeat': repeat,
      'value': JSON.stringify(this.selection.selected)}, { }).subscribe((data: any) => {
      this.gotorepeat(at_id, realrepeat);
      this.toastService.show({
        text: `Invitees have been deleted.`,
        type: 'success',
      });
      this.selection.clear()
    });


  }

  resendinvites(ev_id, at_id, repeat, realrepeat) {



    this.isDisabled = true;

    const headers = new HttpHeaders({       'Accept': 'text/html'});

    const input = new FormData();

    input.append('jevrsvp_listid', '');
    input.append('jevattend_hiddeninitees', '1');
    input.append('rsvp_email', 'reemail');

    for (const r of this.eventdetail.invite_list) {
      //if (r.confirmed !== '1') {
      input.append('atd_id[]', at_id + '|' + repeat);
      input.append('jevinvitee[]', 'rsvp_inv_' + r.id);
      //}
    }

    this.toastService.show({
      text: 'Emails have been sent.',
      type: 'success',
    });


    this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/resend_invite_email',  {
      'ev_id': ev_id,
      'at_id': at_id,
      'repeat': realrepeat,
      'sender_id': this.account.current_profile.id,
      'sender_name': this.account.current_profile.name,
      'value': this.eventdetail}, { }).subscribe((data: any) => {
    });


    this.fake = true;
    this.ready();




  }


  sendinvites(ev_id, at_id, repeat, realrepeat) {



    this.isDisabled = true;

    const headers = new HttpHeaders({       'Accept': 'text/html'});

    const input = new FormData();

    input.append('jevrsvp_listid', '');
    input.append('jevattend_hiddeninitees', '1');
    input.append('rsvp_email', 'reemail');

    for (const r of this.eventdetail.invite_list) {
      //if (r.emailsent === '0' && r.confirmed !== '1') {
      input.append('atd_id[]', at_id + '|' + repeat);
      input.append('jevinvitee[]', 'rsvp_inv_' + r.id);
      //}
    }

    this.toastService.show({
      text: 'Emails have been sent.',
      type: 'success',
    });


    this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/invite_email',  {
      'ev_id': ev_id,
      'at_id': at_id,
      'repeat': realrepeat,
      'sender_id': this.account.current_profile.id,
      'sender_name': this.account.current_profile.name,
      'value': this.eventdetail}, { }).subscribe((data: any) => {
    });

    this.fake = true;
    this.ready();





  }

  invitelist(ev_id, at_id, repeat, realrepeat) {
    const dialogRef = this.dialog.open(DashboardEventviewsinvitelistComponent, {
      width: 'auto',
      height: 'auto',
      data: {'event': ev_id, 'atd': at_id, 'rp': repeat, 'invitelist': this.eventdetail.invitelist, 'summary': this.eventdetail.summary},
      maxHeight: '80vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.gotorepeat(ev_id, realrepeat);
    });


  }

  saveinvites(ev_id, at_id, repeat, realrepeat) {
    const dialogRef = this.dialog.open(DashboardEventviewsavelistComponent, {
      width: 'auto',
      height: 'auto',
      data: {'event': ev_id, 'atd': at_id, 'rp': repeat, 'summary': this.eventdetail.summary},
      maxHeight: '80vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.gotorepeat(ev_id, realrepeat);
    });



  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource2.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource2.data.forEach(row => this.selection.select(<InviteViewElement>row));
  }






  editmainevent(event, rp) {
    this.isDisabled = true;
    this.ref.detectChanges();
    this.sheetRef.dismiss();
    this.router.navigate(['/dashboard/editevent/' + event]);
  }

  editrepeat(event, rp) {
    this.isDisabled = true;
    this.ref.detectChanges();
    this.sheetRef.dismiss();
    this.router.navigate(['/dashboard/editrepeat/' + rp]);
  }





  deleteattendees(attendee_id, repeat_id, atd_id, realrepeat) {
    this.isDisabled = true;
    const headers = new HttpHeaders({       'Accept': 'text/html'});
    const input = new FormData();

    this.http.post(environment.service.trackithubapi + '/api/inside/admincancelregistration?profile_id='+attendee_id+'&at_id='+atd_id+'&rp_id='+realrepeat, input, {headers}).subscribe((data: any) => { }, error => {
      this.toastService.show({
        text: 'Attendee has been deleted.',
        type: 'success',
      });
      this.gotorepeat(atd_id, realrepeat);

    });
  }

  ready() {


    if(this.data.id.tab !== undefined && this.data.id.tab !== null && this.data.id.tab !== ''){
      this.tabGroup.selectedIndex = 1;
    }

    this.listdata = this.eventdetail.attendance_list;

    this.invitedata = this.eventdetail.invite_list;
    if (this.fake === true) {
      this.fake = false;
      this.invitedata.forEach((val, index) => this.invitedata[index].emailsent  = '1');
    }
    if(this.eventdetail.invite_id !== null){
      this.http.get<any>(environment.service.trackithubapi + '/api/inside/updateviewedstatus?inv_id=' + this.eventdetail.invite_id + '&profile_id=' + this.account.current_profile.profile_id).subscribe((data: any) => {

      });

    }



    this.eventdetail.description = this.eventdetail.description.replace(/h3/gi, 'p');

    if (this.eventdetail.is_owner == 1 || this.eventdetail.is_admin == 1) {
      if(this.eventdetail.overrideprice == ''){
        this.displayedColumns = ['name', 'status', 'delete'];
      } else {
        this.displayedColumns = ['name', 'status', 'totalprice', 'paid', 'owed', 'delete', 'note'];
      }
    } else {
      this.displayedColumns = ['name', 'status'];
    }

    if ((Number(this.eventdetail.capacity) + Number(this.eventdetail.waitingcapacity)) > (Number(this.eventdetail.attending) + Number(this.eventdetail.waiting))) {
      this.isover = false;
    } else {
      if(Number(this.eventdetail.capacity) == 0){
        this.isover = false;
      } else {
        this.isover = true;
      }
    }


    this.eventdetail.capacity = Number(this.eventdetail.capacity);
    this.eventdetail.attending = Number(this.eventdetail.attending);


    this.dateobj = new Date(this.eventdetail.date_start);
    this.content = this.sanitizer.bypassSecurityTrustHtml(
      this.eventdetail.description
    );
    this.dataSource.data = this.listdata;
    this.dataSource2.data = this.invitedata;

    this.isDisabled = false;

    this.emailsent = true;

    let count = 0;
    for (const r of this.eventdetail.invite_list) {
      if (r.emailsent !== '1') {
        this.emailsent = false;
      }
      count = count + 1;
    }
    if (count === 0) {
      this.emailsent = false;
    }


    this.calendarService.reload(this.calendar.group_id, this.calendar.profile_id, this.calendar.datestring);
    this.ref.detectChanges();

    this.isPaypal = false;
    this.isPaypal2 = false;


  }

  regclosed() {
    this.toastService.show({
      text: 'Registration is now closed.',
      type: 'warn',
    });
  }

  updateguest($event, types) {
    if (types == 'Select') {
      this.guestcount = $event.value;
    }
  }

  payAttendee(event, atd, rp, owed, withstanding, drp) {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/getinvoiceid?rp_id=' + drp + '&at_id=' + atd + '&profile_id=' + this.account.current_profile.profile_id).subscribe((data: any) => {
      window.location.href = '/index.php?option=com_rsvppro&task=accounts.paymentpage&gateway=paypalipn&Itemid=430&amount=' + withstanding + '&rsvpid=' + atd + '&invoiceid=' + data.invoice_id;
    });
  }

  recordAttendee(event, atd, rp, status, fee, drp, ty) {
    if(status == 0){
      if(ty == 0) {
        const dialogRef = this.dialog.open(DashboardEventviewcancelComponent, {
          width: 'auto',
          height: 'auto',
          data: {
            'is_team': this.eventdetail.is_team
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result == true){
            this.isDisabled = true;
            this.ref.detectChanges();

            const headers = new HttpHeaders({       'Accept': 'text/html'});
            const input = new FormData();
            input.append('jevattend', status);
            input.append('jevattend_hidden', '1');
            input.append('jevattend_id', '0');
            input.append('jevattend_id_approve', '0');
            input.append('guestcount', this.guestcount.toString(10));
            input.append('lastguest', '1');
            input.append('Itemid', '1');

            if (fee != '') {
              input.append('radioselect', '0');
              input.append('xmlfile[field6]', '1');
              input.append('xmlfile[feebalance]', (fee * this.guestcount).toString(10));
              input.append('xmlfile[totalfee]', (fee * this.guestcount).toString(10));
              input.append('xmlfile[field9]', 'paypalipn');
            }

            this.http.post(environment.service.trackithubapi + '/api/inside/cancelregistration?profile_id='+this.account.current_profile.profile_id+'&at_id='+atd+'&rp_id='+rp+'&is_team=' + this.eventdetail.is_team, input, {headers}).subscribe((data: any) => { }, error => {
              if(this.eventdetail.is_team == 1){
                this.toastService.show({
                  text: 'Team registration has been canceled.',
                  type: 'success',
                });
                window.location.href = '/dashboard/overview';
              } else {
                this.toastService.show({
                  text: 'Not Attending Event.',
                  type: 'success',
                });
              }
              this.ref.detectChanges();
              this.gotorepeat(event, rp);
            });


          } else {

          }
        });

      } else {
        const dialogRef = this.dialog.open(DashboardEventviewdeclineComponent, {
          width: 'auto',
          height: 'auto',
          data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result == true){


            this.isDisabled = true;
            this.ref.detectChanges();



            const headers = new HttpHeaders({       'Accept': 'text/html'});

            const input = new FormData();
            input.append('jevattend', status);
            input.append('jevattend_hidden', '1');
            input.append('jevattend_id', '0');
            input.append('jevattend_id_approve', '0');
            input.append('guestcount', this.guestcount.toString(10));
            input.append('lastguest', '1');
            input.append('Itemid', '1');

            if (fee != '') {
              input.append('radioselect', '0');
              input.append('xmlfile[field6]', '1');
              input.append('xmlfile[feebalance]', (fee * this.guestcount).toString(10));
              input.append('xmlfile[totalfee]', (fee * this.guestcount).toString(10));
              input.append('xmlfile[field9]', 'paypalipn');
            }









            this.http.post(environment.service.trackithubapi + '/index.php?option=com_rsvppro&task=attendees.record&at_id=' + atd + '&rp_id=' + rp + '&Itemid=0', input, {headers}).subscribe((data: any) => { }, error => {
              this.toastService.show({
                text: 'Not Attending Event.',
                type: 'success',
              });



              if (fee != '' && status != 0) {
                this.http.get<any>(environment.service.trackithubapi + '/api/inside/getinvoiceid?rp_id=' + drp + '&at_id=' + atd + '&profile_id=' + this.account.current_profile.profile_id).subscribe((data: any) => {
                  window.location.href = '/index.php?option=com_rsvppro&task=accounts.paymentpage&gateway=paypalipn&Itemid=430&amount=' + (fee * this.guestcount) + '&rsvpid=' + atd + '&invoiceid=' + data.invoice_id;
                });
              } else {
                this.gotorepeat(event, rp);
              }

            });

          } else {

          }
        });
      }

    } else {


      this.isDisabled = true;
      this.ref.detectChanges();




      const headers = new HttpHeaders({       'Accept': 'text/html'});

      const input = new FormData();
      input.append('jevattend', status);
      input.append('jevattend_hidden', '1');
      input.append('jevattend_id', '0');
      input.append('jevattend_id_approve', '0');
      input.append('guestcount', this.guestcount.toString(10));
      input.append('lastguest', '1');
      input.append('Itemid', '1');

      if (fee != '') {
        input.append('radioselect', '0');
        input.append('xmlfile[field6]', '1');
        input.append('xmlfile[feebalance]', (fee * this.guestcount).toString(10));
        input.append('xmlfile[totalfee]', (fee * this.guestcount).toString(10));
        input.append('xmlfile[field9]', 'paypalipn');
      }





        this.http.post(environment.service.trackithubapi + '/api/inside/useracceptinvite?profile_id='+this.account.current_profile.profile_id+'&at_id='+atd+'&rp_id='+rp, input, {headers}).subscribe((data: any) => { }, error => {
          this.toastService.show({
            text: 'Attendance has been updated.',
            type: 'success',
          });

          this.gotorepeat(event, rp);
        });
    }
  }

  gotorepeat(event, rp) {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/calendar_events?profile_id=' + this.account.current_profile.profile_id + '&rp_id=' + rp + '&event=' + event).subscribe((data: any) => {
      this.eventdetail = data;
      this.ready();
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource2.sort = this.sort2;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilter2(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
    if (this.s2 != null) {
      this.s2.complete();
    }
  }

  opengroupselect(event, atd, rp, realrepeat) {
    const dialogRef = this.dialog.open(DashboardEventviewinvitegroupsComponent, {
      width: 'auto',
      height: 'auto',
      data: {'event': event, 'atd': atd, 'rp': rp, 'summary': this.eventdetail.summary},
      maxHeight: '80vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.gotorepeat(event, realrepeat);
    });
  }

  openeventnotes(atd_id, notes, realrepeat) {
    const dialogRef = this.dialog.open(DashboardEventviewnotesComponent, {
      width: 'auto',
      height: 'auto',
      data: {'atd_id': atd_id, 'notes': notes},
      maxHeight: '80vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.gotorepeat(event, realrepeat);
    });
  }

  openContactSheet(event, group_id, group_owner) {
    event.preventDefault();
    this.bottomSheetService.openBottomSheetWithData(DashboardContactSheetComponent, 'DashboardContactSheetComponent',{
      group: group_id,
      id: this.account.current_profile.profile_id,
      name: this.account.current_profile.name,
      owner: group_owner
    });
  }
}

@Component({
  selector: 'app-dashboard-eventview-notes',
  templateUrl: 'dashboard-eventview-notes.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./dashboard-eventview-notes.scss'],
})
export class DashboardEventviewnotesComponent implements OnDestroy {


  toppings = new FormControl();
  s1 = null;
  account = null;

  constructor(
    public dialogRef: MatDialogRef<DashboardEventviewnotesComponent>, private http: HttpClient, private toastService: ToastService, private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public datax: any) {

    this.s1 = this.accountService.cast.subscribe(datax => {
      this.account = datax;
    });
  }

  closemodal() {
    this.dialogRef.close(null);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onYesClick(): void {
    this.http.post<any>(environment.service.trackithubapi + '/api/inside/updatenotes', {
      'notes': this.toppings.value,
      'atd_id': this.datax.atd_id}).subscribe((data: any) => {
      this.toastService.show({
        text: 'Notes have been updated.',
        type: 'success',
      });
      this.dialogRef.close(null);
    });
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }
}

@Component({
  selector: 'app-dashboard-eventview-invitegroups',
  templateUrl: 'dashboard-eventview-invitegroups.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./dashboard-eventview-invitegroups.scss'],
})
export class DashboardEventviewinvitegroupsComponent implements OnDestroy {

  toppings = new FormControl();
  s1 = null;
  account = null;

  constructor(
    public dialogRef: MatDialogRef<DashboardEventviewinvitegroupsComponent>, private http: HttpClient, private toastService: ToastService, private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public datax: DialogData) {

    this.s1 = this.accountService.cast.subscribe(datax => {
      this.account = datax;
    });
  }

  closemodal() {
    this.dialogRef.close(null);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onYesClick(): void {
    this.http.post<any>(environment.service.trackithubapi + '/api/inside/groupinvitelist', {
      'groups': this.toppings.value,
      'at_id': this.datax.atd,
      'repeat_id': this.datax.rp,
      'event': this.datax.event,
      'summary': this.datax.summary}).subscribe((data: any) => {
      this.toastService.show({
        text: data.num + ' new users have been saved to the invitee list.',
        type: 'success',
      });
      this.dialogRef.close(null);
    });
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }
}

@Component({
  selector: 'app-dashboard-eventview-savelist',
  templateUrl: 'dashboard-eventview-savelist.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./dashboard-eventview-savelist.scss'],
})
export class DashboardEventviewsavelistComponent implements OnDestroy {

  toppings = new FormControl();
  s1 = null;
  account = null;

  constructor(
    public dialogRef: MatDialogRef<DashboardEventviewsavelistComponent>, private http: HttpClient, private toastService: ToastService, private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public datax: DialogData) {

    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
    });
  }

  closemodal() {
    this.dialogRef.close(null);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onYesClick(): void {
    this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/saveinvitelist', {
      'listname': this.toppings.value,
      'user_id': this.account.current_profile.profile_id,
      'at_id': this.datax.atd,
      'repeat_id': this.datax.rp, 'summary': this.datax.summary}, { }).subscribe((data: any) => {
      this.toastService.show({
        text: `Invite list has been saved.`,
        type: 'success',
      });
      this.dialogRef.close(null);
    });
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }
}

@Component({
  selector: 'app-dashboard-eventview-invitelist',
  templateUrl: 'dashboard-eventview-invitelist.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./dashboard-eventview-invitelist.scss'],
})
export class DashboardEventviewsinvitelistComponent implements OnDestroy {
  toppings = new FormControl();
  s1 = null;
  account = null;

  constructor(
    public dialogRef: MatDialogRef<DashboardEventviewsavelistComponent>, private http: HttpClient, private toastService: ToastService, private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public datax: DialogData) {
    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
    });
  }

  closemodal() {
    this.dialogRef.close(null);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onYesClick(): void {
    this.http.post<RosterElement>(environment.service.trackithubapi + '/api/inside/invitelist', {
      'list_id': this.toppings.value,
      'at_id': this.datax.atd,
      'repeat_id': this.datax.rp,
      'event': this.datax.event,
      'summary': this.datax.summary}, { }).subscribe((data: any) => {
      this.toastService.show({
        text: `List was invited.`,
        type: 'success',
      });
      this.dialogRef.close(null);
    });

  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

}

@Component({
  selector: 'app-dashboard-eventview-decline',
  templateUrl: 'dashboard-eventview-decline.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./dashboard-eventview-decline.scss'],
})

export class DashboardEventviewdeclineComponent implements OnDestroy {

  s1 = null;
  account = null;

  constructor(
    public dialogRef: MatDialogRef<DashboardEventviewsavelistComponent>, private http: HttpClient, private toastService: ToastService, private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public datax: DialogData) {}

    closemodal() {
      this.dialogRef.close(null);
    }

    onNoClick(): void {
      this.dialogRef.close(null);
    }

    ngOnDestroy() {
  }
}

@Component({
  selector: 'app-dashboard-eventview-cancel',
  templateUrl: 'dashboard-eventview-cancel.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./dashboard-eventview-cancel.scss'],
})
export class DashboardEventviewcancelComponent implements OnDestroy {

  s1 = null;
  account = null;

  constructor(
    public dialogRef: MatDialogRef<DashboardEventviewsavelistComponent>, private http: HttpClient, private toastService: ToastService, private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public datax: DialogData) {
  }

  closemodal() {
    this.dialogRef.close(null);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  ngOnDestroy() {

  }
}
