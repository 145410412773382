import {
  ChangeDetectionStrategy,
  Component, Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {AccountService} from "../../common/services/account.service";


@Component({
  selector: 'app-dashboard-leaguemodal',
  templateUrl: './dashboard-leaguemodal.component.html',
  styleUrls: ['./dashboard-leaguemodal.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class DashboardLeaguemodalComponent implements OnInit, OnDestroy {

  account: any;
  name: string;

  constructor(public dialogRef: MatDialogRef<DashboardLeaguemodalComponent>, private accountService: AccountService, @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('----- this is inside the modal',data.event.name)
    this.name = data.event.name;
  }




  ngOnDestroy() {

  }

  ngOnInit() {

  }

  closepop(){
    this.dialogRef.close();
  }



}
