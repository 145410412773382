import {
  ChangeDetectionStrategy,
  Component,
  ChangeDetectorRef,
  OnDestroy,
  Inject,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { SessionStorageService} from 'ngx-webstorage';
import { MygroupsService} from '../../common/services/mygroups.service';
import {AccountService} from '../../common/services/account.service';
import {Account} from "../../common/interfaces/account";
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../common/toast";
import {ModalService} from "../../common/services/modal.service";
import {DashboardLeagueScoreComponent} from "../dashboard-leaguescore/dashboard-league-score.component";
import {MatDialog} from "@angular/material/dialog";
import {BottomSheetService} from "../../common/services/bottom-sheet.service";
import {DashboardComplainSheetComponent} from "../dashboard-complain-sheet/dashboard-complain-sheet.component";
import {ChatService} from "../../common/services/chat.service";
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-dashboard-myleagues',
  templateUrl: './dashboard-myleagues.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})


export class DashboardMyleaguesComponent implements OnDestroy, AfterViewInit {
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;
  account: Account;
  profile_id: any;
  timer: any;

  variable1: any;
  variable2: any;

  ldata: any;

  s2 = null;


  w1 = false;
  w2 = false;
  w3 = false;
  w4 = false;
  w5 = false;
  w6 = false;
  w7 = false;

  hate = false;


  constructor(
      private sessionSt: SessionStorageService,
      private mygroups: MygroupsService,
      private accountService: AccountService,
      private http: HttpClient,
      private toastService: ToastService,
      private ref: ChangeDetectorRef,
      private modalService: ModalService,
      public dialog: MatDialog,
      private bottomSheetService: BottomSheetService, private  chatService: ChatService,
      @Inject(MAT_BOTTOM_SHEET_DATA) public datax: any
  ) {
    this.s2 = this.accountService.cast.subscribe(data => {
      this.profile_id = data.current_profile.profile_id;
      this.redrawscreen(this.datax);
      console.log(this.tabGroup);
    });

    ref.detach();
    this.timer = setInterval(() => {
      this.ref.detectChanges();
    }, 750);
  }

  sheetRef: any = this.datax.ref;

  standings(roundrobin){
    window.location.href = '/dashboard/standings?type=roundrobin';
  }

  report(roundrobin) {
    this.bottomSheetService.openBottomSheetWithData(DashboardComplainSheetComponent, 'DashboardComplainSheetComponent',{
      week: 1,
      rr: roundrobin,
    });
  }
  contact(roundrobin) {
    this.bottomSheetService.openBottomSheetWithData(DashboardComplainSheetComponent, 'DashboardComplainSheetComponent',{
      week: 1,
      rr: roundrobin,
    });
  }

  click(rr) {
    this.http.get<any>(environment.service.trackithubapi + '/api/inside/loadleaguescore?&profile_id=' + this.profile_id + '&roundrobin_id=' + rr).subscribe((data: any) => {

      const dialogRef = this.dialog.open(DashboardLeagueScoreComponent, {
        maxWidth: 500,
        minWidth: 370,
        data: {data: data}
      });

      dialogRef.afterClosed().subscribe(result => {
        this.redrawscreen(null);
      });
    });
  }

  setCurrentRow(item){
    this.variable1 = 1;
    this.variable2 = item;
  }

  redrawscreen(rr){
    this.http.get<Account>(environment.service.trackithubapi + '/api/inside/get_league_info?profile_id=' + this.profile_id).subscribe((leagueData: any) => {

      this.ldata = leagueData.league_info;
      this.hate = true;

      console.log('-----',rr);


      if(rr.id != '') {
        this.click(rr.id);

        this.ldata.current.forEach((item) => {
          if(item.players[0].roundrobin_id == rr.id){
            this.tabGroup.selectedIndex = 0;
          }
        });

        this.ldata.past.forEach((item) => {
          if(item.players[0].roundrobin_id == rr.id){
            this.tabGroup.selectedIndex = 1;
          }
        });
      }

      if(rr.id == ''){
        this.tabGroup.selectedIndex = 0;
      }

    });
  }



  gotoChat(convo_id){

    console.log(convo_id);

    this.http.get<any>(environment.service.trackithubapi + '/api/inside/lastchatcheck?from_id=' + this.profile_id).subscribe((data: any) => {
      if(data.result == 'fail') {
        this.toastService.show({
          text: 'You have not chatted with anyone yet.',
          type: 'warn',
          buttonText: '',
        });
      } else {

        this.chatService.openchatconvo({
          conversationID: convo_id,
          profile_id: data.profile_id,
          name: data.name,
          avatarImg: data.avatarImg,
          avatarPlaceholder: data.avatarPlaceholder,
          email: data.email,

        });
      }
    });
  }

  ngOnDestroy() {
    clearInterval(this.timer);
    if (this.s2 != null) {
      this.s2.complete();
      window.location.href='/dashboard/overview';
    }
  }

  ngAfterViewInit() {
    console.log('Tab Group:', this.tabGroup); // Check if this logs the MatTabGroup component
  }

}
